<template>
  
    <form role="form" @submit="onFormSubmit" name="update-profile">
        <div class="form-group row">
          <label for="updateCivility" class="col-lg-3 col-form-label">{{ $t('profile.info.civility') }}</label>
          <div class="col-lg-9">
            <select id="updateCivility" class="form-control" v-model="form.civility">
              <option value="">Select</option>
              <option v-for="(civility, index) in civilities" :key="index" :value="civility">{{$t('civilities.long.'+civility)}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
            <label for="updateFirstName" class="col-lg-3 col-form-label">{{ $t('profile.info.firstName') }}</label>
            <div class="col-lg-9">
              <input id="updateFirstName" type="text" :placeholder="$t('profile.info.firstName_placeholder')" class="form-control" v-model="form.firstName" />
            </div>
        </div>
        <div class="form-group row">
            <label for="updateMiddleName" class="col-lg-3 col-form-label">{{ $t('profile.info.middleName') }}</label>
            <div class="col-lg-9">
              <input id="updateMiddleName" type="text" :placeholder="$t('profile.info.middleName_placeholder')" class="form-control" v-model="form.middleName" />
            </div>
        </div>
        <div class="form-group row">
            <label for="updateLastName" class="col-lg-3 col-form-label">{{ $t('profile.info.lastName') }}</label>
            <div class="col-lg-9">
              <input id="updateLastName" type="text" :placeholder="$t('profile.info.lastName_placeholder')" class="form-control" v-model="form.lastName" />
            </div>
        </div>

        <Address 
            v-bind:value.sync="form.address" 
            :options="addressOptions"
            :labels="addressLabels"
            :labelAsColumn="true"
            required
          />

        <Phone 
            id="phone" 
            v-bind:value.sync="form.phones[0]" 
            :label="'Téléphone'" 
            labelAsColumn
            required 
        />

        <TextField 
          v-bind:value.sync="form.meta.matricule" 
          id="matricule" 
          :label="'Matricule'" 
          :placeholder="'Matricule'" 
          required 
          labelAsColumn 
        />
        
        <Select
            :label="'Statut'"
            :placeholder="'Statut'"
            :selectOptions="{
                options: listStatus,
                getOptionLabel : option => option.name,
                reduce : option => option.value
            }"
            v-bind:value.sync="form.meta.status" 
            :required="true"
            labelAsColumn
        />
        
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('profile.account.update') }}</button>
    </form>

</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import * as api from '@fwk-client/utils/api';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Address from '@fwk-client/components/panels/input/Address.vue';
import Phone from '@fwk-client/components/panels/input/Phone.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useUsers } from '../../../../../composables/useUsers';
import { useSiteAdmin } from '../../../composables/useSiteAdmin';


export default defineComponent({
    props: {
      user : {
        type: Object,
        required: true
      }
    },
    components: {
      TextField,
      Address,
      Phone,
      Select
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedSite } = useSiteAdmin(props, context);

      const civilities = ["MR","MRS"];

      const listStatus:any[] = [
            {
                name: "Sapeur pompier professionnel (SPP)",
                value: "SPP"
            },
            {
                name: "Sapeur pompier volontaire (SPV)",
                value: "SPV"
            },
            {
                name: "Personnel administratif (PATS)",
                value: "PATS"
            },
            {
                name: "Retraité",
                value: "RETIRED"
            }
        ];

      const addressOptions = {
            showName: false,
            showNote: false,
            labelAsColumn: false,
            nbAddressLines: 2,
            listCountries: "WORLD"
        }

      const addressLabels = {
            addressLines:[
                app.$t("address.form.addressLine")
            ],
            addressLines_placeholder:[
                app.$t("address.form.addressLine1_placeholder"),
                app.$t("address.form.addressLine2_placeholder")
            ],
            postalCode:app.$t("address.form.postalCode"),
            postalCode_placeholder:app.$t("address.form.postalCode_placeholder"),
            city:app.$t("address.form.city"),
            city_placeholder:app.$t("address.form.city_placeholder"),
            country:app.$t("address.form.country"),
            country_select:app.$t("address.form.country_select")
        }

      const profile = props.user.profile;

      const form:any = {
        civility: profile.civility ? profile.civility : "",
        ...profile
      };

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=update-profile] button.ladda-button' );
        laddaSubmit = Ladda.create(button!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        var input = {
          "userID" : props.user._id,
          ...form,
          "companyID" : form.company ? form.company._id : undefined,
        }

        var options:api.ApiVueOptions =  {
          app: app
        }

        laddaSubmit!.start();
        
        api.postAPI('/api/admin/cms/site/'+selectedSite.value.site._id+'/users/profile/update', input, options).then((response:any) => {
          if(response.updated) {
            // We emit event as profile is updated
            context.emit('profile-updated', response.user); 
          }
          laddaSubmit!.stop();
        });
      }

      return {
        civilities,
        addressOptions,
        addressLabels,
        listStatus,
        form,
        onFormSubmit
      }
    }
})
</script>