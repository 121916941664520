<template>
  <form role="form" @submit="onFormSubmit" name="site-switch-form">
    
    <Select
      v-if="listSites.length > 0"
      :label="$t('cms.site.switch.label')"
      :placeholder="$t('cms.site.switch.select_placeholder')"
      :selectOptions="{
        options: listSites,
        getOptionLabel : option => option.name
      }"
      v-bind:value.sync="form.site" 
      :labelAsColumn="false"
    />
    
    <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('cms.site.switch.button') }}</button>
  </form>
</template>

<style>
  form[name=site-switch-form] {
    display: flex;
  }

  form[name=site-switch-form] > div {
    display: flex;
    margin-bottom: 0;
    flex-grow:1;
  }

  form[name=site-switch-form] > div > label {
    margin-right:10px;
    line-height: 36px;
    margin-bottom: 0px;
  }

  form[name=site-switch-form] > div > div {
    flex-grow:1;
  }

  form[name=site-switch-form] > button {
    margin-left:10px;
  }
</style>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Select from '@fwk-client/components/panels/input/Select.vue';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';

import { useSiteAdmin } from '../../composables/useSiteAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      Select
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { sites, selectedSite, selectSite } = useSiteAdmin(props, context);

      const form:any = reactive({
        site: selectedSite ? selectedSite.value.site : undefined
      })
    
      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=site-switch-form] button' );
        laddaSubmit = Ladda.create(button!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        laddaSubmit!.start();
        selectSite(form.site._id).then(() => {
          laddaSubmit!.stop();
        });
      }

      const listSites = computed(() => {
        return sites.value.map((site:any) => {
          return site.site;
        })
      })

      return { 
        listSites,
        form,
        selectedSite,
        onFormSubmit
      }
    }
})
</script>