<template>
  <div class="ibox theme">
    <div class="ibox-title">
      <h2>{{ $t('cms.site.users.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row m-b-md">
        <div class="col-auto">
          <TextField
            :label="$t('cms.site.users.list.displayName.label')"
            :placeholder="$t('cms.site.users.list.displayName.placeholder')"
            :size="$t('cms.site.users.list.displayName.placeholder').length + 1"
            :labelClass="{'col-auto':true, 'col-form-label':true}"
            v-bind:value.sync="filters.displayName" 
            :labelAsColumn="true"
          />
        </div>
        <div class="col-auto">
          <DropdownList
            :label="$t('cms.site.users.list.status.label')"
            :list="availableStatus"
            :getLabel="getStatusLabel"
            v-bind:value.sync="filters.status" 
          />
        </div>
      </div>

      <div class="row">

        <p>{{ $t('cms.site.users.list.number-of-users', {nbUsers:numberOfRows}) }}</p>

        <b-table 
            outlined striped
            :items="displayedUsers"
            :fields="listFields"
            ref="listUsersTable">
          <template v-slot:cell(isLoggedIn)="row">
            <i v-if="row.item.isLoggedIn" :class="['fa','fa-user-circle-o','active']"></i>
          </template>
          <template v-slot:cell(isActive)="row">
            <i :class="['fa','fa-check',row.item.isActive ? 'active' : 'disabled']"></i>
          </template>
          <template v-slot:cell(options)="row">
            <span v-if="!row.item.isActive">
              <a href="javascript:void(0)" @click="confirmActivateUser(row.item)">{{ $t('cms.site.users.list.options.activate') }}</a>
              /  
            </span>
            <a href="javascript:void(0)" @click="showUpdateUserModal(row.item)">{{ $t('cms.site.users.list.options.update') }}</a>
            /
            <a href="javascript:void(0)" @click="showUpdateProfileModal(row.item)">{{ $t('cms.site.users.list.options.profile') }}</a>
            /
            <a href="javascript:void(0)" @click="confirmRemoveUser(row.item)">{{ $t('cms.site.users.list.options.delete') }}</a>
          </template>
        </b-table>


        <b-modal size="lg" ref="updateUserModal" :title="'User details ('+ userToUpdate.login +')'" hide-footer lazy>
          <UpdateUser :user="userToUpdate" v-on:user-updated="onUserUpdated" />
        </b-modal>

        <b-modal size="lg" ref="updateProfileModal" :title="'Profile details ('+ userToUpdate.login +')'" hide-footer lazy>
          <UpdateProfile :user="userToUpdate" v-on:profile-updated="onProfileUpdated" />
        </b-modal>

        <b-modal ref="removeUserModal" 
            hide-header
            @ok="onRemoveUser">
            {{ $t('cms.site.users.list.delete-confirmation', {userName: userToRemove.login}) }}
        </b-modal>

        <b-modal ref="activateUserModal" 
            hide-header
            @ok="onActivateUser">
            {{ $t('cms.site.users.list.activate-confirmation', {userName: userToUpdate.login}) }}
        </b-modal>

      </div>
    
    </div>
  </div>
</template>

<style scoped>
.fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  td div.pretty {
    margin:0;
  }
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { formatDate, formatDay } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';
import UpdateUser from './Update.vue';
import UpdateProfile from './Profile.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import DropdownList from '@fwk-client/components/panels/input/DropdownList.vue';

import { useUsers } from '../../../../../composables/useUsers';
import { useSiteAdmin } from '../../../composables/useSiteAdmin';

export default defineComponent({
    props: {
    },
    components: {
      TextField,
      DropdownList,
      UpdateUser,
      UpdateProfile
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedSite } = useSiteAdmin(props, context);
      const { listUsers, removeUser, updateUsers, activateUser, updateSite, filters, availableStatus } = useUsers({
        ...props,
        site: ref(selectedSite.value.site)
      }, context);

      // We check when there is a new site selected
      watch(
        () => selectedSite.value.site._id,
        (val:any, oldVal:any) => {
          // We update the user when the selected site is updated
          updateSite(selectedSite.value.site);
        },
        { deep: false }
      )

      const currentLanguageCode = computed(() => {
        return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
      })

      const listLoading:Ref<boolean> = ref(false);

      const emptyUser = {
        "login" : ""
      };

      const userToUpdate:Ref<any> = ref({...emptyUser});
      const userToRemove:Ref<any> = ref({...emptyUser});

      const listFields:Ref<any[]> = ref([
          {
            key: "isLoggedIn",
            label: ""
          },
          {
            key: "login",
            label: ""
          },
          {
            key: "profile.displayName",
            label: ""
          },
          {
            key: "lastConnectionDateTime",
            label: "",
            formatter: (value:any) => {
              if(!value) { return ""; }
              return formatDate(value, currentLanguageCode.value);
            }
          },
          {
            key: "isActive",
            label: ""
          },
          {
            key: "options",
            label: ""
          }
        ]);
      
      onMounted(() => {
        updateTableLabels();
      })

      const updateTableLabels = () => {
        listFields.value[0].label = app.$t('cms.site.users.list.headers.isLoggedIn') as string;
        listFields.value[1].label = app.$t('cms.site.users.list.headers.login') as string;
        listFields.value[2].label = app.$t('cms.site.users.list.headers.displayName') as string;
        listFields.value[3].label = app.$t('cms.site.users.list.headers.lastConnection') as string;
        listFields.value[4].label = app.$t('cms.site.users.list.headers.active') as string;
        listFields.value[5].label = app.$t('cms.site.users.list.headers.options') as string;
      }

      const updateUserModal:Ref<HTMLElement|null> = ref(null);
      const showUpdateUserModal = (user:any) => {
        userToUpdate.value = user;
        // @ts-ignore
        updateUserModal.value.show()
      }

      const removeUserModal:Ref<HTMLElement|null> = ref(null);
      const confirmRemoveUser = (user:any) => {
        userToRemove.value = user;
        // @ts-ignore
        removeUserModal.value.show()
      }

      const activateUserModal:Ref<HTMLElement|null> = ref(null);
      const confirmActivateUser = (user:any) => {
        userToUpdate.value = user;
        // @ts-ignore
        activateUserModal.value.show()
      }

      const updateProfileModal:Ref<HTMLElement|null> = ref(null);
      const showUpdateProfileModal = (user:any) => {
        userToUpdate.value = user;
        // @ts-ignore
        updateProfileModal.value.show()
      }

      const onRemoveUser = () => {
        removeUser(userToRemove.value);
      }

      const onActivateUser = () => {
        activateUser(userToUpdate.value);
      }

      const onUserUpdated = () => {
        updateUsers();
      }

      const onProfileUpdated = () => {
        updateUsers();
      }

      const numberOfRows:Ref<number> = computed(() => {
          return listUsers.value.length
      })

      const displayedUsers = computed(() => {
        var filterDisplayName:string|undefined = (filters.displayName && filters.displayName != "") ? filters.displayName.normalize("NFD").replace(/\p{Diacritic}/gu, "") : undefined;
        return listUsers.value.filter((user:any) => {
          var userDisplayName = user.profile.displayName ? user.profile.displayName.normalize("NFD").replace(/\p{Diacritic}/gu, "") : "";
          var userStatus = user.isActive ? "ACTIVE" : "INACTIVE";
          
          var display = true;
          if(filterDisplayName) {
            display = display && userDisplayName.toLowerCase().indexOf(filterDisplayName.toLowerCase()) > -1;
          }
          if(filters.status) {
            display = display && filters.status.indexOf(userStatus) > -1;
          }
          return display;
        }).sort((userA:any, userB:any) => {
          return userA.profile.lastName.localeCompare(userB.profile.lastName, currentLanguageCode.value)
        })
      })

      const getStatusLabel = (statusCode:string) => {
        return {
          "ACTIVE" : app.$t("cms.site.users.list.status.active"),
          "INACTIVE" : app.$t("cms.site.users.list.status.inactive")
        }[statusCode];
      }

      watch(
        currentLanguageCode,
        (val:any, oldVal:any) => {
        updateTableLabels();
        },
        { deep: false }
      )

      return { 
        listFields,
        displayedUsers,
        numberOfRows,
        listLoading,
        updateUserModal, 
        removeUserModal,
        activateUserModal, 
        updateProfileModal,
        confirmActivateUser,
        showUpdateUserModal,
        showUpdateProfileModal,
        confirmRemoveUser,
        userToUpdate,
        userToRemove,
        onUserUpdated,
        onProfileUpdated,
        onRemoveUser,
        onActivateUser,
        filters,
        availableStatus,
        getStatusLabel
      }
    }
})
</script>