<template>
    <div class="row m-b-lg m-t-lg">
        <div class="col-md-6">
          <div v-if="logoURL" class="profile-image">
              <img :src="logoURL" class="rounded-circle circle-border" alt="logo">
          </div>
          <div :class="(logoURL ? 'profile-info' : '')">
            <h2 class="no-margins">{{ name }}</h2>
            <p>
              <span v-if="selectedSite">{{$t('cms.info.code')}} <a v-if="siteLink" :href="siteLink" target="_blank">{{ code }}</a><span v-else>{{ code }}</span><br/></span>
              {{$t('cms.info.company')}} {{ companyName }}<br/>
              <span v-if="selectedSite">{{$t('cms.info.id',{siteID: selectedSite.site._id})}}</span>
            </p>
          </div>
        </div>
        <div class="col-md-6">
            <SiteSwitch v-if="sites.length > 1"></SiteSwitch>
        </div>
        <div v-if="selectedSiteTopLevelDomain" class="col-md-12">
          <a v-if="selectedSiteTopLevelDomain" class="btn btn-primary" :href="siteValidationLink" target="_blank">{{$t('cms.deployment.validation.button-site', {'domain': selectedSiteTopLevelDomain})}}</a>
        </div>
      </div>
</template>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { getPath } from '@igotweb-node-api-utils/misc';
import SiteSwitch from './SiteSwitch.vue';
import { languagesTypes } from '@fwk-client/store/types';

import { useSiteAdmin } from '../../composables/useSiteAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      SiteSwitch
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedSite, selectedSiteTopLevelDomain, sites, siteValidationLink } = useSiteAdmin(props, context);

      const logoURL = computed(() => {
        return null;
        if(selectedSite) { 
      // We get the site logo URL from content default header
      var siteLogoURL = getPath(selectedSite, "content.header.props.options.logoURL");
			if(siteLogoURL) {
        // We need to get the Logo URL with statics domain computed from server
				// return this.getComputedMediaURL(siteLogoURL);
			}
    }
      });

      const name = computed(() => {
        if(selectedSite) { return selectedSite.value.site.name; }
        return "";
      })

      const code = computed(() => {
        if(selectedSite && selectedSite.value.site.code) { return selectedSite.value.site.code; }
        return "-";
      })

      const companyName = computed(() => {
        if(selectedSite && selectedSite.value.site.company) { return selectedSite.value.site.company.name; }
        return "-";
      })

      const id = computed(() => {
        if(selectedSite) { return selectedSite.value.site._id; }
        return "-";
      })

      const siteLink = computed(() => {
        if(selectedSiteTopLevelDomain.value) {
          return "https://www."+selectedSiteTopLevelDomain.value;
        }
        return null;
      })

      return { 
        selectedSiteTopLevelDomain,
        siteValidationLink,
        sites,
        selectedSite,
        logoURL,
        name,
        code,
        companyName,
        id,
        siteLink
      }
    }
})
</script>