<template>
  
    <form role="form" @submit="onFormSubmit" name="update-user">
        <div class="form-group row">
            <label for="updateDetailsLogin" class="col-lg-3 col-form-label">Login</label>
            <div class="col-lg-9">
              <input id="updateLogin" type="text" readonly :placeholder="$t('profile.info.login_placeholder')" class="form-control" v-model="form.login" />
            </div>
        </div>
        <div class="form-group row">
            <label for="updateDetailsIsActive" class="col-lg-3 col-form-label">Is active</label>
            <div class="col-lg-9">
              <p-check id="updateDetailsIsActive" class="p-switch p-fill" color="success" v-model="form.isActive">&nbsp;</p-check>
            </div>
        </div>
        <button class="btn btn-primary ladda-button update-details" data-style="zoom-in" type="submit">Update details</button>
    </form>

</template>

<style scoped>
  .sk-spinner {
    height: 33px;
    padding-top: 7px;
    text-align: left;
    margin: 0;
  }
</style>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import { useUsers } from '../../../../../composables/useUsers';
import { useSiteAdmin } from '../../../composables/useSiteAdmin';


export default defineComponent({
    props: {
      user : {
        type: Object,
        required: true
      }
    },
    components: {
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { user } = toRefs(props);

      const { selectedSite } = useSiteAdmin(props, context);
      const { updateUser, updateUserFormForUpdate, form } = useUsers({
        ...props,
        site: ref(selectedSite.value.site)
      }, context);

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var updateDetailsButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-user] button.ladda-button.update-details' );
        laddaSubmit = Ladda.create(updateDetailsButton!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        laddaSubmit!.start();

        updateUser().then(() => {
          laddaSubmit!.stop();
        })
      }

      // We check when there is a new shop selected
      watch(
        user,
        (val:any, oldVal:any) => {
          // We update the user form
          updateUserFormForUpdate(user.value);
        },
        { deep: true }
      )

      return { 
        form,
        onFormSubmit
      }
    }
})
</script>